import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/HomePage/PartnerSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/HomePage/PricingSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/HomePage/TopSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/Navbar/PageSettingsButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ActionIcon"] */ "/app/node_modules/@mantine/core/esm/components/ActionIcon/ActionIcon.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/app/node_modules/@mantine/core/esm/components/Button/Button.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Card"] */ "/app/node_modules/@mantine/core/esm/components/Card/Card.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Container"] */ "/app/node_modules/@mantine/core/esm/components/Container/Container.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Group"] */ "/app/node_modules/@mantine/core/esm/components/Group/Group.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SimpleGrid"] */ "/app/node_modules/@mantine/core/esm/components/SimpleGrid/SimpleGrid.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Stack"] */ "/app/node_modules/@mantine/core/esm/components/Stack/Stack.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/core/esm/components/Text/Text.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/core/esm/core/Box/Box.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
