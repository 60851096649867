'use client';

import { ActionIcon, Button, useMantineColorScheme } from '@mantine/core';
import { IconBrightnessDown, IconSun } from '@tabler/icons-react';
import { useTranslations } from 'next-intl';

export function ThemeSwitch() {
  const t = useTranslations('Navbar.Settings.ThemeSwitch');
  const { colorScheme, setColorScheme } = useMantineColorScheme();

  const toggleColorScheme = () => {
    setColorScheme(colorScheme === 'dark' ? 'light' : 'dark');
  };

  return (
    <Button
      onClick={toggleColorScheme}
      variant="transparent"
      leftSection={colorScheme === 'dark' ? <IconBrightnessDown /> : <IconSun />}
    >
      {t('change-theme')}
    </Button>
  );
}
