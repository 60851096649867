import useWrappedRouter from '@/src/useWrappedRouter';
import { Button, Group } from '@mantine/core';
import { IconWorld } from '@tabler/icons-react';
import { getCookie, setCookie } from 'cookies-next';

export function LanguageButton() {
  const router = useWrappedRouter();

  const currentLanguage = getCookie('language') || 'en';

  const setLanguageCookie = (language: string) => {
    setCookie('language', language);
    router.refresh();
    //window.location.reload();
  };
  return (
    <Group justify="center">
      <Button
        onClick={() => setLanguageCookie(currentLanguage === 'en' ? 'de' : 'en')}
        variant="transparent"
        leftSection={<IconWorld />}
      >
        {currentLanguage === 'en' ? 'Switch to German' : 'Zu englisch wechseln'}
      </Button>
    </Group>
  );
}
