'use client';
import { Card, Container, SimpleGrid, Text, useMantineColorScheme } from '@mantine/core';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import hillCodeLogoDark from '../../public/logos/hillcode_dark.png';
import hillCodeLogoLight from '../../public/logos/hillcode_light.png';

export default function PartnersSection() {
  const t = useTranslations('HomePage.Partners');
  const { colorScheme } = useMantineColorScheme();

  return (
    <section className="py-16">
      <Container size="xl">
        <Text size="35px" fw="bold" mb="xl" className="text-center">
          {t('title')}
        </Text>
        <SimpleGrid cols={{ base: 1, md: 3, lg: 4 }} spacing="md">
          <PartnerCard logo={t('ravensdruck.title')} role={t('ravensdruck.description')} />
          <PartnerCard
            logo={colorScheme === 'light' ? hillCodeLogoDark.src : hillCodeLogoLight.src}
            role={t('hillcode.description')}
            url="https://hillcode.de"
          />
        </SimpleGrid>
      </Container>
    </section>
  );
}

type PartnerCardProps = {
  logo?: string;
  url?: string;
  role?: string;
};
const PartnerCard = ({ logo, url, role }: PartnerCardProps) => {
  return (
    // For SEO purposes, we olny use an anchor tag if the partner has a website
    <Card shadow="sm" padding="xl" component={url ? 'a' : undefined} href={url} target="_blank">
      <div className="flex items-center justify-center aspect-auto h-8">
        {logo?.startsWith('/') ? (
          <Image
            src={logo || '/logo.png'}
            alt="Bistro Display"
            width={100}
            height={30}
            className="select-none"
            draggable="false"
          />
        ) : (
          <Text size="xl" fw={700}>
            {logo || 'Bistro Display'}
          </Text>
        )}
      </div>
      <Text mt="xl" c="dimmed" size="sm" className="text-center">
        {role
          ? role
          : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nec odio.'}
      </Text>
    </Card>
  );
};
