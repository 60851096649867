'use client';
import { Box, Flex, Stack, Text } from '@mantine/core';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { TextLoop } from 'react-text-loop-next';
import illustration from '../../public/bistro_illustration.svg';

export default function TopSection() {
  const t = useTranslations('HomePage');

  const LoopText = () => (
    <TextLoop interval={3333}>
      <span>{t('typeSequence.1')}</span>
      <span>{t('typeSequence.2')}</span>
      <span>{t('typeSequence.3')}</span>
    </TextLoop>
  );

  return (
    <Flex className="h-screen" direction={{ base: 'column', md: 'row' }}>
      <Box className="w-full h-full flex items-center justify-center lg:mr-16">
        <Stack visibleFrom="md">
          <Text fw="bold" size="48px" span>
            {t('digital-menu-cards-for')}&nbsp;
            <Text c="blue" fw="bold" mt="md" span>
              <LoopText />
            </Text>
          </Text>
          <Text fw="lighter" size="40px" mt="lg">
            {t('digital-signage-bottom')}
          </Text>
        </Stack>
        <Stack hiddenFrom="md">
          <Text fw="bold" size="48px" span>
            {t('digital-menu-cards-for')}&nbsp;
            <Text c="blue" fw="bold" mt="md" className="inline" span>
              <LoopText />
            </Text>
          </Text>
          <Text fw="lighter" size="40px" mt="lg" className="">
            {t('digital-signage-bottom')
              .split(' ')
              .map((word, index) => (
                <span key={index} className="block pt-2">
                  {word}
                </span>
              ))}
          </Text>
        </Stack>
      </Box>
      <Box className="w-full flex items-center justify-center">
        <Image
          src={illustration}
          alt="illustration"
          width={500}
          height={400}
          className="select-none"
          draggable="false"
          loading="eager"
        />
      </Box>
      <div className="grow" />
    </Flex>
  );
}
