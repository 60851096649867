'use client';
import { Carousel, CarouselSlide } from '@mantine/carousel';
import '@mantine/carousel/styles.css';
import {
  Badge,
  Box,
  Card,
  Center,
  List,
  ListItem,
  rem,
  SegmentedControl,
  SimpleGrid,
  Stack,
  Text,
  ThemeIcon,
  useMantineTheme,
} from '@mantine/core';
import { IconCheck, IconCornerLeftDown, IconPercentage, IconX } from '@tabler/icons-react';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

export default function PricingSection() {
  const [billingCycle, setBillingCycle] = useState<'monthly' | 'annually'>('monthly');
  const t = useTranslations('HomePage.Pricing');
  const theme = useMantineTheme();

  return (
    <section>
      <div className="pb-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <Text size="2.5rem" fw="bold" mb="xl">
            {t('designed-for-businisses-like-yours')}
          </Text>
          <Text size="xl" c="dimmed">
            {t('description')}
          </Text>
        </div>
        <Box className="w-full flex flex-row justify-center mb-4">
          <Stack className="justify-center">
            <Text size="lg" className="mb-4 text-center">
              {t('billing-cycle')}
            </Text>
            <Box className="relative" mt="lg">
              <SegmentedControl
                data={[
                  {
                    label: t('billing-cycle-monthly'),
                    value: 'monthly',
                  },
                  {
                    label: (
                      <Center style={{ gap: 4 }}>
                        <span>{t('billing-cycle-annually')}</span>
                        <IconPercentage style={{ width: rem(16), height: rem(16) }} />
                      </Center>
                    ),
                    value: 'annually',
                  },
                ]}
                color="blue"
                value={billingCycle}
                onChange={(c) => setBillingCycle(c as 'monthly' | 'annually')}
                className="mb-8"
                radius="xl"
              />
              <Box className="flex items-center absolute -top-6 left-36 w-full">
                <ThemeIcon color="blue" size="md" variant="transparent">
                  <IconCornerLeftDown />
                </ThemeIcon>
                <Text className="inline-block" size="xs" c="blue" fw={700} component="sup">
                  {t('save-money')}
                </Text>
              </Box>
            </Box>
          </Stack>
        </Box>
        <SimpleGrid cols={{ base: 1, lg: 3 }} spacing="md" hiddenFrom="lg">
          <PricingCard
            index={2}
            billingCycle={billingCycle}
            price={{
              monthly: 19,
              annually: 9,
            }}
            description={t('PricingCard.description.essential')}
            tierName={t('PricingCard.tierName.essential')}
            features={{
              individualConfiguration: true,
              noHiddenFees: true,
            }}
          />
          <PricingCard
            index={0}
            billingCycle={billingCycle}
            price={{
              monthly: 39,
              annually: 29,
            }}
            description={t('PricingCard.description.basic')}
            tierName={t('PricingCard.tierName.basic')}
            features={{
              hardwareInclusive: true,
              individualConfiguration: true,
              noHiddenFees: true,
              freeTextChanges: 1,
            }}
          />
          <PricingCard
            index={1}
            billingCycle={billingCycle}
            price={{
              monthly: 69,
              annually: 59,
            }}
            highlighed
            description={t('PricingCard.description.advanced')}
            tierName={t('PricingCard.tierName.advanced')}
            features={{
              hardwareInclusive: true,
              individualConfiguration: true,
              noHiddenFees: true,
              freeTextChanges: 3,
              freeDesignChanges: 1,
              useVideos: true,
            }}
          />
          <PricingCard
            index={2}
            billingCycle={billingCycle}
            price={{
              monthly: 99,
              annually: 89,
            }}
            description={t('PricingCard.description.enterprise')}
            tierName={t('PricingCard.tierName.enterprise')}
            features={{
              hardwareInclusive: true,
              individualConfiguration: true,
              noHiddenFees: true,
              freeTextChanges: 3,
              freeDesignChanges: 2,
              useVideos: true,
              uploadOwnDesigns: true,
            }}
          />
        </SimpleGrid>
        <Carousel
          visibleFrom="lg"
          height="auto"
          slideSize={`${100 / 3}%`}
          slideGap="md"
          align="start"
          containScroll="trimSnaps"
          slidesToScroll={1}
          mt="lg"
          classNames={{ viewport: '!overflow-visible' }}
        >
          <CarouselSlide>
            <PricingCard
              index={2}
              billingCycle={billingCycle}
              price={{
                monthly: 19,
                annually: 9,
              }}
              description={t('PricingCard.description.essential')}
              tierName={t('PricingCard.tierName.essential')}
              features={{
                individualConfiguration: true,
                noHiddenFees: true,
              }}
            />
          </CarouselSlide>
          <CarouselSlide>
            <PricingCard
              index={0}
              billingCycle={billingCycle}
              price={{
                monthly: 39,
                annually: 29,
              }}
              description={t('PricingCard.description.basic')}
              tierName={t('PricingCard.tierName.basic')}
              features={{
                hardwareInclusive: true,
                individualConfiguration: true,
                noHiddenFees: true,
                freeTextChanges: 1,
              }}
            />
          </CarouselSlide>
          <CarouselSlide>
            <PricingCard
              index={1}
              billingCycle={billingCycle}
              price={{
                monthly: 69,
                annually: 59,
              }}
              highlighed
              description={t('PricingCard.description.advanced')}
              tierName={t('PricingCard.tierName.advanced')}
              features={{
                hardwareInclusive: true,
                individualConfiguration: true,
                noHiddenFees: true,
                freeTextChanges: 3,
                freeDesignChanges: 1,
                useVideos: true,
              }}
            />
          </CarouselSlide>
          <CarouselSlide>
            <PricingCard
              index={2}
              billingCycle={billingCycle}
              price={{
                monthly: 99,
                annually: 89,
              }}
              description={t('PricingCard.description.enterprise')}
              tierName={t('PricingCard.tierName.enterprise')}
              features={{
                hardwareInclusive: true,
                individualConfiguration: true,
                noHiddenFees: true,
                freeTextChanges: 3,
                freeDesignChanges: 2,
                useVideos: true,
                uploadOwnDesigns: true,
              }}
            />
          </CarouselSlide>
        </Carousel>
        <Box className="mt-4">
          <Text size="sm" c="dimmed">
            <sup>*</sup>
            {t('hardware-disclaimer')}
          </Text>
          <Text size="sm" c="dimmed">
            <sup>**</sup>
            {t('pricing-disclaimer')}
          </Text>
        </Box>
      </div>
    </section>
  );
}

type PricingCardProps = {
  index: number;
  billingCycle: 'monthly' | 'annually';
  price: { monthly: number; annually: number };
  description: string;
  tierName: string;
  highlighed?: boolean;
  features: {
    hardwareInclusive?: boolean;
    individualConfiguration?: boolean;
    noHiddenFees?: boolean;
    freeTextChanges?: number;
    freeDesignChanges?: number;
    useVideos?: boolean;
    uploadOwnDesigns?: boolean;
  };
};

const X = () => (
  <ThemeIcon color="gray" size="xl" variant="transparent" className="-mr-2">
    <IconX size={24} className="w-32" />
  </ThemeIcon>
);

const Check = () => (
  <ThemeIcon color="green" size="xl" variant="transparent" className="-mr-2">
    <IconCheck size={24} className="" />
  </ThemeIcon>
);

const PricingCard = ({
  index,
  tierName,
  billingCycle,
  price,
  features: {
    hardwareInclusive = false,
    individualConfiguration = false,
    noHiddenFees = false,
    freeTextChanges = 0,
    freeDesignChanges = 0,
    useVideos = false,
    uploadOwnDesigns = false,
  },
  description,
  highlighed,
}: PricingCardProps) => {
  const t = useTranslations('HomePage.Pricing.PricingCard');

  return (
    <Box className={'relative h-full'}>
      {highlighed && (
        <Badge color="blue" className="mb-4 absolute -top-2 right-[5%] z-[1]" variant="filled">
          {t('most-popular')}
        </Badge>
      )}
      <Card
        radius="md"
        className="flex flex-col p-6 text-center rounded-lg shadow xl:p-8 h-full"
        shadow={highlighed ? '0px 0px 20px 1px var(--mantine-primary-color-filled)' : undefined}
        withBorder
      >
        <Text fw="bolder" size="xl" mb="md">
          {tierName}
        </Text>
        <Text c="dimmed">{description}</Text>
        <Box className="grow" />
        <div className="flex justify-center items-baseline my-8">
          <Text span mr={8} size="3rem" fw="bolder">
            {price[billingCycle]}€
          </Text>
          <Text span c="dimmed">
            /{t('month')}
          </Text>
        </div>
        <List spacing="xs" size="sm" className="mb-8 text-left" icon={<Check />} mx="auto">
          <ListItem icon={individualConfiguration ? <Check /> : <X />}>
            {t('features.individual-configuration')}
          </ListItem>
          <ListItem icon={noHiddenFees ? <Check /> : <X />}>
            {t('features.no-hidden-fees')}
          </ListItem>
          <ListItem icon={hardwareInclusive ? <Check /> : <X />}>
            {t('features.hardware-inclusive')}
            {!hardwareInclusive && '*'}
          </ListItem>
          <ListItem icon={freeTextChanges > 0 ? <Check /> : <X />}>
            {t('features.free-text-changes')}
            {freeTextChanges > 0 && (
              <>
                {': '}
                <span className="font-semibold">
                  {freeTextChanges}
                  <sup>**</sup>{' '}
                  <Text span size="xs" fw="lighter">
                    {t('per-year')}
                  </Text>
                </span>
              </>
            )}
          </ListItem>
          <ListItem icon={freeDesignChanges > 0 ? <Check /> : <X />}>
            {t('features.free-design-changes')}
            {freeDesignChanges > 0 && (
              <>
                {': '}
                <span className="font-semibold">
                  {freeDesignChanges}
                  <sup>**</sup>{' '}
                  <Text span size="xs" fw="lighter">
                    {t('per-year')}
                  </Text>
                </span>
              </>
            )}
          </ListItem>
          <ListItem icon={useVideos ? <Check /> : <X />}>
            {t('features.use-videos')}
            <Badge color="blue" className="ml-2" variant="outline">
              soon
            </Badge>
          </ListItem>
          <ListItem icon={uploadOwnDesigns ? <Check /> : <X />}>
            {t('features.upload-own-designs')}
            <Badge color="blue" className="ml-2" variant="outline">
              soon
            </Badge>
          </ListItem>
        </List>
      </Card>
    </Box>
  );
};
