'use client';
import { nprogress } from '@mantine/nprogress';
import { PrefetchOptions } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { usePathname, useRouter } from 'next/navigation';

export default function useWrappedRouter() {
  const router = useRouter();
  const currentPath = usePathname();

  const triggerProgressIndicator = (path: string) => {
    if (path !== currentPath) nprogress.start();
  };

  const wrappedRouter = {
    push: (path: string) => {
      triggerProgressIndicator(path);
      router.push(path);
    },
    replace: (path: string) => {
      triggerProgressIndicator(path);
      router.replace(path);
    },
    back: () => {
      triggerProgressIndicator('/!/');
      router.back();
    },
    forward: () => {
      triggerProgressIndicator('/!/');
      router.forward();
    },
    refresh: () => {
      router.refresh();
    },
    prefetch: (href: string, options?: PrefetchOptions) => {
      router.prefetch(href, options);
    },
    currentPath,
  };
  return wrappedRouter;
}
