'use client';
import { ActionIcon, ActionIconProps, HoverCard, Stack } from '@mantine/core';
import { IconSettings } from '@tabler/icons-react';
import { LanguageButton } from './LanguageButton';
import { ThemeSwitch } from './ThemeSwich';

export default function PageSettingsButton({ ...buttonProps }: ActionIconProps) {
  return (
    <>
      <HoverCard shadow="md">
        <HoverCard.Target>
          <ActionIcon variant="transparent" {...buttonProps}>
            <IconSettings />
          </ActionIcon>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <Stack align="start">
            <ThemeSwitch />
            <LanguageButton />
          </Stack>
        </HoverCard.Dropdown>
      </HoverCard>
    </>
  );
}
